<template>
  <div>
  <div class="card card-custom">
  <div class="card-header">
  <div class="card-title"><h3 class="card-label">Componenten</h3></div>
  
	  <div class="card-toolbar">
			<div>
			  <a href="#" @click="createItem">Add new</a>		
		</div>
		</div>
  
  </div>
  
  <div class="card-body"><div v-if="message" class="spinner"></div>
  <table v-if="dataForTable" class="table">
	<thead>
		<tr>
			<th scope="col"><div class="checkbox-list"><label class="checkbox"><input type="checkbox" name="Checkboxes1"><span></span></label></div></th>
			<th scope="col" v-for="item in dataForTable.columns" :key="item.title">{{item.title}}</th>
		</tr>
	</thead>
	<tbody>
		<tr v-for="item in dataForTable.items" :key="item.id">
			<td scope="row"><div class="checkbox-list"><label class="checkbox"><input type="checkbox" name="Checkboxes1"><span></span></label></div></td>
			<td scope="col" v-for="itemIn in dataForTable.columns" :key="itemIn.title">
			<span v-if="itemIn.type == 'field'">{{itemIn.html}}</span>
			<span v-else-if="itemIn.type == 'status'"><span class="label label-inline label-light-primary font-weight-bold">Actief</span></span>
			<span v-else-if="itemIn.type == 'statusReturn'"><span class="label label-inline label-light-danger font-weight-bold">Niet actief</span></span>
			<span v-else-if="itemIn.type == 'editButton'"><router-link :to="'/'+dataForTable.route+'/'+item[itemIn.field]" :data-table-item-id="item[itemIn.field]" class="btn btn-sm btn-clean btn-icon mr-2" data-toggle="dropdown"><span class="menu-icon svg-icon"><icon name='edit'></icon></span></router-link></span>
			<span v-else>{{item[itemIn.field]}}</span>
			</td>
			
		</tr>
		
	</tbody>
  </table>
  </div>
  
  
  
  </div>
  </div>
</template>

<style lang="scss">
	.dropdown-toggle {
	   border: none!important;
	   outline: none!important;
	   text-decoration: none!important;
	}
</style>

<script>
import axios from 'axios'
// import Tables from '@/components/tables/Tables'
import createForm from '@/views/template/component/addNewModal.vue'
import Icon from '@/components/icons/Icons.vue';

export default {
  data () {
    return {
      message: null,
      dynamicComponent: null,
	  dynamicNewItemForm: null,
	  dataForTable: null,
	  dataForForm: null,
	  create: null,
	  form: {name:"<new>"}
    }
  },
  // props: ['message'],
  methods: {
	async refreshTable () {
	  this.message = true
	  this.dataForTable = null
	  this.dynamicComponent = null
	  this.create = null
      axios({
        method: 'get',
        url: 'https://keetels-api.eliteswitch.com/api/components',
      }).then((response) => {
        // this.message = this.$store.state.auth.user.id + response.status
		response.data.result.route = 'template/component'
		response.data.result.columns = [
			// {"title":"ID","field":"id","type":"text"},
			{"title":"Name","field":"name","type":"text"},
			{"title":"Route","field":"route","type":"text"},
			{"title":"Title","field":"title","type":"text"},
			{"title":"Icon","field":"icon","type":"text"},
			{"title":"Status","field":'status',"type":"status"},
			{"title":"","field":"uid","type":"editButton"}
		]
		response.data.result.fields = [
			{"label":"Component name","field":"name","name":"name","type":"text"}
		]
		this.create = true
		this.dataForTable = response.data.result
		this.dataForForm = response.data.result
		this.message = null
		// this.dynamicComponent = Tables
		this.dynamicNewItemForm = createForm
      }, (error) => {
        console.log(error)
      })
    },
	createItem(){
		this.spinner = 'spinner spinner-sm spinner-success spinner-right'
		 this.submitButton = null
		  axios.post('https://keetels-api.eliteswitch.com/api/component', this.form)
		  .then((response) => {
		  console.log(response)
		  
		  this.refreshTable()

			this.spinner = ''
		  }, (error) => {
			console.log(error)
			this.submitButton = true
			this.spinner = ''
		  })

		// this.$bvModal.hide(modalId)
	},
  },
  mounted () {
    this.refreshTable()
  },
  // computed: mapState({
  //   myValue () {
  //     return this.$store.state.auth.user.email
  //   }
  // }),
  components: {
	 Icon
	}
}
</script>
